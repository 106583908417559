import React from 'react';
import { Link, graphql, PageProps } from 'gatsby';
import readingTime from 'reading-time';
import { BLOCKS, Block, Inline } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Options } from '@contentful/rich-text-react-renderer';
import { paramCase } from 'param-case';

import { BlogPostBySlugQuery } from '../../gatsby-graphql';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Tags from '../components/tags';
import * as styles from './blog-post.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '../components/container';
import Section from '../components/section';
import Column from '../components/column';

const convertNodeContentToParamCaseStringForAnchor = (node: Block | Inline) => {
  const anchorId = node.content
    .map((t) => {
      if (t.nodeType === 'text') {
        return t.value;
      }
      return '';
    })
    .join();
  if (anchorId) {
    return paramCase(anchorId);
  }
};

const defaultNodeRenderers: Options = {
  renderNode: {
    // [BLOCKS.PARAGRAPH]: (node, children) => <p id={paramCase(node.content.map((v) => v.value))}>{children}</p>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 id={convertNodeContentToParamCaseStringForAnchor(node)}>{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 id={convertNodeContentToParamCaseStringForAnchor(node)}>{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 id={convertNodeContentToParamCaseStringForAnchor(node)}>{children}</h3>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
      console.log('BLOCKS.EMBEDDED_ASSET', node);
      if (!node?.data?.target) {
        return null;
      }
      const { gatsbyImageData } = node.data.target;
      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return <GatsbyImage alt={node.data.alt || ''} image={gatsbyImageData} />
    },
    // [BLOCKS.HEADING_4]: (node, children) => <h4 id={paramCase(node.content)}>{children}</h4>,
    // [BLOCKS.HEADING_5]: (node, children) => <h5 id={paramCase(node.content)}>{children}</h5>,
    // [BLOCKS.HEADING_6]: (node, children) => <h6 id={paramCase(node.content)}>{children}</h6>,
    // [BLOCKS.UL_LIST]: (node, next) => <ul>{next(node.content)}</ul>,
    // [BLOCKS.OL_LIST]: (node, next) => <ol>{next(node.content)}</ol>,
    // [BLOCKS.LIST_ITEM]: (node, next) => <li>{next(node.content)}</li>,
    // [BLOCKS.QUOTE]: (node, next) => <blockquote>{next(node.content)}</blockquote>,
    // [BLOCKS.HR]: () => <hr />,
    // [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node),
    // [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node),
    // [INLINES.EMBEDDED_ENTRY]: node => defaultInline(INLINES.EMBEDDED_ENTRY, node),
    // [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri}>{next(node.content)}</a>,
  },
};


const BlogPostTemplate: React.FC<PageProps<BlogPostBySlugQuery>> = (props) => {
  const { post, previous, next } = props.data;
  if (!post?.title) {
    throw new Error('No title');
  }
  const plainTextDescription = post?.description?.description || '';
  const plainTextBody =
    post?.body && post?.body.raw ? documentToPlainTextString(JSON.parse(post.body.raw)) : '';
  const { text: timeToReadText } = readingTime(plainTextBody);

  const authors: { name: string; url: string }[] = [];
  const editors: { name: string; url: string }[] = [];
  if (post.author?.name) {
    authors.push({ name: post.author.name, url: `/author/${paramCase(post.author.name)}` });
  }
  if (post.authors && post.authors.length > 0) {
    post.authors.forEach((author) => {
      if (author?.name && author?.name !== 'Placeholder') {
        authors.push({ name: author.name, url: `/author/${paramCase(author.name)}` });
      }
    });
  }
  if (post.editors && post.editors.length > 0) {
    post.editors.forEach((editor) => {
      if (editor?.name && editor?.name !== 'Placeholder') {
        editors.push({ name: editor.name, url: `/author/${paramCase(editor.name)}` });
      }
    });
  }

  let publishEditedDateSection;

  if (post.editedDate) {
    publishEditedDateSection = (
      <div>
        <div>
          Published: <time dateTime={post.rawPublishDate}>{post.publishDate}</time>
        </div>

        <div>
          Updated: <time dateTime={post.rawEditedDate}>{post.editedDate}</time>
        </div>
      </div>
    )
  } else {
    publishEditedDateSection = (
      <div>
        <div>
          Published: <time dateTime={post.rawPublishDate}>{post.publishDate}</time>
        </div>
      </div>
    )
  }

  return (
    <Layout location={props.location}>
      <Seo
        title={post?.title}
        description={plainTextDescription}
        image={post?.heroImage?.resize?.src ? `http:${post.heroImage?.resize?.src}` : ''}
      />
      <Section>
        <Container>
          <Column>
            <Hero
              image={post?.heroImage?.gatsbyImageData}
              title={post.title}
            // content={post.description}
            />

            <div className={styles.meta}>
              <div>
                {authors.length > 0 && (
                  <>
                    By{' '}
                    {authors.map((author, index) => (
                      <span key={`author-${index}`}>
                        {index > 0 && ', '}
                        <Link className="author-link" to={author.url}>
                          {author.name}
                        </Link>
                      </span>
                    ))}
                  </>
                )}
                {editors.length > 0 && (
                  <>
                    {' '}
                    • Editor{' '}
                    {editors.map((editor, index) => (
                      <span key={`editor-${index}`}>
                        {index > 0 && ', '}
                        <Link className="author-link" to={editor.url}>
                          {editor.name}
                        </Link>
                      </span>
                    ))}
                  </>
                )} - {timeToReadText}
              </div>
              {publishEditedDateSection}
            </div>
            <div className={styles.article}>
              {post.body && (
                <div className={styles.body}>
                  {post.body?.raw && renderRichText(post?.body as any, defaultNodeRenderers)}
                </div>
              )}
              <Tags tags={post.tags || []} />
              {(previous || next) && (
                <nav className={styles.articleNavigation}>
                  <ul className={styles.articleNavigationList}>
                    {previous && (
                      <li>
                        <Link to={`/blog/${previous.slug}`} rel="prev">
                          ← {previous.title}
                        </Link>
                      </li>
                    )}
                    {next && (
                      <li>
                        <Link to={`/blog/${next.slug}`} rel="next">
                          {next.title} →
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </div>
          </Column>
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $previousPostSlug: String, $nextPostSlug: String) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      author {
        name
      }
      authors {
        name
      }
      editors {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawPublishDate: publishDate
      editedDate(formatString: "MMMM Do, YYYY")
      rawEditedDate: editedDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1200)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 300)
            __typename
          }
        }
      }
      tags
      description {
        description
        childMdx {
          body
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`;
